import { ListingType } from "@shopware-pwa/composables-next";
import { UnwrapNestedRefs } from "vue";

declare type LocationQueryValue = string | null

/**
 * UI composable
 *
 * @returns
 */
export function useFacettedSearch(selectedOptionIds: any, prices: any, searchCriteriaForRequest: ComputedRef<{
  [code: string]: string | string[] | number | number[] | boolean | undefined;
}>, sidebarSelectedFilters: UnwrapNestedRefs<{
  [key: string]: any;
}>, listingType: ListingType) {
  const { apiInstance } = useShopwareContext();
  const { getInitialFilters, search, filtersToQuery } = useListing({
    listingType: listingType,
    defaultSearchCriteria: {
      limit: 24,
    }
  });
  const route = useRoute();
  const router = useRouter();

  function arrayToEmptyObject(arr: any) {
    return arr.reduce((obj: any, key: string) => {
      obj[key] = []; // Use an empty string as the value
      return obj;
    }, {});
  }

  let endpoint = 'store-api/search';

  if (listingType === "categoryListing") {
    const { category } = useCategory();
    const categoryId = category.value?.id;

    endpoint = "/store-api/product-listing/" + categoryId;
  }

  let { isLoading, refreshOpen } = useFacettedSearchHelpers();
  let debounceTime = Date.now();
  let criteria: string[] = ['color', 'size', 'material'];
  let criteriaLabel: { [index: string]: String } = {
    'color': 'Farbe',
    'size': 'Größe',
    'material': 'Material'
  };
  let lastFilterQry: String = String(route.query.lastFilterQry) ?? "";
  let propFilter = arrayToEmptyObject(criteria);
  let lastMin = "";
  let lastMax = "";
  let lastFilterProps: LocationQueryValue | LocationQueryValue[] = "";

  let priorFilter = arrayToEmptyObject(criteria);
  const currentFilterOpts: any = ref({
    color: {},
    material: {},
    size: {},
  });
  const currentPriceOpts = ref({ min: null, max: null });

  provide("selectedOptionIds", selectedOptionIds);
  provide('prices', prices);
  provide("currentFilterOpts", currentFilterOpts);
  provide("currentPriceOpts", currentPriceOpts);

  const setPropFilters = function () {
    let curFilter = Object.assign({}, searchCriteriaForRequest.value);

    for (let criterium of criteria) {
      let possibleOptions = getInitialFilters?.value?.find((el) => el.name === criteriaLabel[criterium])?.options?.map((el2) => el2.id) ?? [];
      let critFilter = String(curFilter?.properties ?? '').split('|').filter((val: any) => possibleOptions.includes(val)) ?? [];

      for (let f of critFilter) {
        propFilter[criterium].push(f);
      }
    }
  }

  let abortController = new AbortController();

  const refreshPromises: (promises?: Promise<void>[], force?: boolean) => Promise<void>[] = function (promises: Array<Promise<void>> = [], force = false) {
    // - Get data for each criteria
    for (let criterium of criteria) {
      if (lastFilterQry != criterium) {
        promises.push((async (): Promise<void> => {
          let curFilter = Object.assign({}, searchCriteriaForRequest.value);

          let lastFilter = String(lastFilterProps).split('|').filter((val: any) => !priorFilter[criterium].includes(val)) ?? [];
          let lastFilterStr: string = lastFilter.join('|');

          curFilter.properties = String(curFilter?.properties).split('|').filter((val: any) => !propFilter[criterium].includes(val)) ?? [];
          curFilter.properties = curFilter.properties.join('|');

          if (lastFilterStr !== curFilter.properties || lastMin != curFilter['min-price'] || lastMax != curFilter['max-price'] || force) {

            if (currentPriceOpts.value.min === Number(prices.value[0])) {
              delete curFilter['min-price'];
            }

            if (currentPriceOpts.value.max === Number(prices.value[1])) {
              delete curFilter['max-price'];
            }

            const tmp: any = await apiInstance.invoke.post(endpoint, {
              ...curFilter,
              'search': listingType !== 'categoryListing' ? (route?.query?.query ?? undefined) : undefined,
              'reduce-aggregations': true,
              'only-aggregations': true,
            }, {
              signal: abortController.signal
            });

            currentFilterOpts.value[criterium] = tmp?.data?.aggregations?.properties?.entities ?? [];

            // Wähle alte Filter automatisch ab, die nicht in der Aggregations sind
            let possibleOptions = currentFilterOpts.value[criterium].find((el: any) => el.name === criteriaLabel[criterium])?.options?.map((el2: any) => el2.id) ?? [];

            // Zu entfernende Optionen
            let removeFilter = propFilter[criterium].filter((element: any) => !possibleOptions.includes(element));


            for (let f of removeFilter) {
              sidebarSelectedFilters['properties'].delete(f);
              propFilter[criterium] = propFilter[criterium].filter((el: any) => el !== f);
            }

            if (removeFilter.length > 0) {
              debounceRefresh();
            }
          }
          else {
            lastFilterQry = criterium;
          }
        })())
      }
    }

    if (lastFilterQry !== 'price') {
      promises.push((async (): Promise<void> => {
        // - Price?
        let curFilter = Object.assign({}, searchCriteriaForRequest.value);

        if ((curFilter.min !== Number(prices.value[0]) && (curFilter.max !== Number(prices.value[1]))) || force) {
          delete curFilter['max-price'];
          delete curFilter['min-price'];

          const tmp: any = await apiInstance.invoke.post(endpoint, {
            ...curFilter,
            'search': listingType !== 'categoryListing' ? (route?.query?.query ?? undefined) : undefined,
            'reduce-aggregations': true,
            'only-aggregations': true,
          }, {
            signal: abortController.signal
          });

          let oldPrices = Object.assign({}, currentPriceOpts.value);

          currentPriceOpts.value = tmp?.data?.aggregations?.price ?? [];

          if (oldPrices.min === Number(prices.value[0])) {
            prices.value[0] = currentPriceOpts.value.min;
            sidebarSelectedFilters["min-price"] = prices.value[0];
          }

          if (oldPrices.max === Number(prices.value[1])) {
            prices.value[1] = currentPriceOpts.value.max;
            sidebarSelectedFilters["max-price"] = prices.value[1];
          }

          if ((currentPriceOpts.value.min ?? 0) > prices.value[0]) {
            prices.value[0] = currentPriceOpts.value.min;
            sidebarSelectedFilters["min-price"] = prices.value[0];
          }

          if (prices.value[0], (currentPriceOpts.value.max ?? 0) < prices.value[1]) {
            prices.value[1] = currentPriceOpts.value.max;
            sidebarSelectedFilters["max-price"] = prices.value[1];
          }
          else {
            lastFilterQry = 'price';
          }
        }
      })());
    }

    return promises;
  }

  function setLastValues() {
    priorFilter = JSON.parse(JSON.stringify(propFilter));
    lastFilterProps = String(searchCriteriaForRequest.value.properties);
    lastMin = String(searchCriteriaForRequest.value['min-price']);
    lastMax = String(searchCriteriaForRequest.value['max-price']);
  }

  const refresh = async function () {
    debounceTime = Date.now();
    refreshOpen.value = false;

    abortController.abort();

    abortController = new AbortController();

    let query: any = {
      query: route.query.query,
      ...filtersToQuery(searchCriteriaForRequest.value)
    };

    if (lastFilterQry)
      query.lastFilterQry = String(lastFilterQry);

    await router.push({
      query
    });

    await Promise.all([
      ...refreshPromises(),
      search({
        ...searchCriteriaForRequest.value,
        ...route.query
      })
    ]);

    isLoading.value = false;
    setLastValues();
  }

  const debounceRefresh = async function () {
    if (debounceTime < Date.now() + 1000) {
      isLoading.value = true;
      refresh();
    }
    else {
      if (refreshOpen.value == false) {
        refreshOpen.value = true;
        isLoading.value = true;
        setTimeout(() => refresh(), 400);
      }
    }
  }

  const reset = async function () {
    setPropFilters();

    Promise.all(refreshPromises([], true)).then(() => setLastValues());
  }

  reset();

  return {
    propFilter,
    debounceRefresh,
    reset,
    isLoading,
  }
}