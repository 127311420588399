<script setup lang="ts">
import { computed, ComputedRef, reactive, UnwrapNestedRefs } from "vue";
import { ListingType, useListing } from "@shopware-pwa/composables-next";
import { ListingFilter, ListingFilterCode } from "@shopware-pwa/types";
import { useHeader } from "~/composables/useHeader";

const props = withDefaults(defineProps<{
    listingType?: ListingType,
  }>(),
  {
    listingType: "categoryListing",
  }
);

const { getCurrentSortingOrder, getInitialFilters, search, filtersToQuery, getTotal } = useListing({
  listingType: props.listingType,
  defaultSearchCriteria: {
    limit: 24,
  }
});

const route = useRoute();
const router = useRouter();

const mapComponent = (code: ListingFilterCode, displayType?: string): string => {
  const components: { [key: string]: string } = {
    features: "ListingFiltersFeatures",
    price: "ListingFiltersPrice",
    properties: "ListingFiltersProperties",
  };

  const componentsDisplayType: { [key: string]: string } = {
    color: "ListingFiltersColor",
    material: "ListingFiltersMaterial",
    passform: "ListingFiltersPassform",
    size: "ListingFiltersSize",
  };

  if (displayType) {
    return componentsDisplayType[displayType];
  }

  return components[code];
};

const sidebarSelectedFilters: UnwrapNestedRefs<{
  [key: string]: any;
}> = reactive<{
  manufacturer: Set<any>;
  properties: Set<any>;
  "min-price": string | number | undefined;
  "max-price": string | number | undefined;
}>({
  manufacturer: new Set(),
  properties: new Set(),
  "min-price": undefined,
  "max-price": undefined,
});

const searchCriteriaForRequest: ComputedRef<{
  [code: string]: string | string[] | number | number[] | boolean | undefined;
}> = computed(() => ({
  // turn Set to array and then into string with | separator
  manufacturer: [...sidebarSelectedFilters.manufacturer]?.join("|"),
  // turn Set to array and then into string with | separator
  properties: [...sidebarSelectedFilters.properties]?.join("|"),
  "min-price": sidebarSelectedFilters["min-price"],
  "max-price": sidebarSelectedFilters["max-price"],
  order: getCurrentSortingOrder.value,
  "shipping-free": sidebarSelectedFilters["shipping-free"],
  rating: sidebarSelectedFilters.rating,
  search: (route.query.query && props.listingType == 'productSearchListing') ?? undefined,
}));

for (const param in route.query) {
  // eslint-disable-next-line no-prototype-builtins
  if (sidebarSelectedFilters.hasOwnProperty(param)) {
    if (sidebarSelectedFilters[param] && typeof sidebarSelectedFilters[param] === "object") {
      (route.query[param] as unknown as string).split("|").forEach((element) => {
        sidebarSelectedFilters[param].add(element);
      });
    } else {
      sidebarSelectedFilters[param] = route.query[param];
    }
  }
}

const selectedOptionIds = computed(() => [
  ...sidebarSelectedFilters.properties,
  ...sidebarSelectedFilters.manufacturer,
]);

const prices = computed({
  get: () => {
    return  [sidebarSelectedFilters["min-price"] || 
             getInitialFilters?.value?.find((filter: ListingFilter) => filter.code === 'price')?.min || 1, 
             sidebarSelectedFilters["max-price"] || 
             getInitialFilters?.value?.find((filter: ListingFilter) => filter.code === 'price')?.max || 100];
  },
  set: 
    function(value: number[]) { 
      if (value[1] !== sidebarSelectedFilters["max-price"])
      {
        sidebarSelectedFilters["max-price"] = value[1];
        onOptionSelectToggle({
          code: "max-price",
          name: "",
          value: value[1]
        });
      }

      if (value[0] !==  sidebarSelectedFilters["min-price"])
      {
        sidebarSelectedFilters["min-price"] = value[0];
        onOptionSelectToggle({
          code: "min-price",
          name: "",
          value: value[0]
        });
      }

      return;
  }
});

const { propFilter, debounceRefresh, reset, isLoading } = useFacettedSearch(selectedOptionIds, prices, searchCriteriaForRequest, sidebarSelectedFilters, props.listingType);

const onOptionSelectToggle = async ({ code, name, value }: { code: string; name: string; value: any }) => {
  // TODO: Handle Size/Color
  if (!["properties", "manufacturer"].includes(code)) {
    sidebarSelectedFilters[code] = value;
  } else if (sidebarSelectedFilters[code]?.has(value)) {
    sidebarSelectedFilters[code]?.delete(value);
  } else {
    sidebarSelectedFilters[code]?.add(value);
  }

  if (name !== "") {
    if (propFilter[name].includes(value)) {
      propFilter[name] = propFilter[name].filter((el: any) => el !== value);
    } else {
      propFilter[name]?.push(value);
    }
  }

  debounceRefresh();
};

const clearFilters = async () => {
  sidebarSelectedFilters.manufacturer.clear();
  sidebarSelectedFilters.properties.clear();
  sidebarSelectedFilters["min-price"] = undefined;
  sidebarSelectedFilters["max-price"] = undefined;

  reset();

  await router.push({
    query: {
      query: route.query.query,
      ...filtersToQuery(searchCriteriaForRequest.value),
    },
  });
  await search({
    ...route.query,
    ...filtersToQuery(searchCriteriaForRequest.value),
  });

};

async function invokeCleanFilters() {
  await search({
    ...route.query
  });
  clearFilters();
}

const countFilters = computed<number>(() => {
  const maxPrice: number = (sidebarSelectedFilters["max-price"]) ? (sidebarSelectedFilters["max-price"] !== getInitialFilters?.value?.find((filter: ListingFilter) => filter.code === 'price')?.max ? 1 : 0) : 0;
  const minPrice: number = (sidebarSelectedFilters["min-price"]) ? (sidebarSelectedFilters["min-price"] !== getInitialFilters?.value?.find((filter: ListingFilter) => filter.code === 'price')?.min ? 1 : 0) : 0;
  return selectedOptionIds.value.length + maxPrice + minPrice;
})

const showResetFiltersButton = computed<boolean>(() => {
  return (countFilters.value > 0);
});

const { isNavigationBarOpened } = useHeader();
isNavigationBarOpened.value = false;

const filterModalController = useFilterModal();
</script>

<template>
  <div v-bind="$attrs">
    <div v-if="showResetFiltersButton" class="sticky top-0 xl:top-18 3xl:top-22 z-2 mx--1 py-8 border-t-px border-maas-border-grey-light bg-maas-background-default">
      <SharedFormButton class="w-full" type="button" layout="secondaryDark" @click="invokeCleanFilters">{{ $t("listing.resetFilters") }}</SharedFormButton>
    </div>

    <component
      :is="
        mapComponent(
          filter.code,
          (filter.name == 'Größe' ? 'size' : '') ||
            (filter.name == 'Material' ? 'material' : '') ||
            filter.displayType ||
            undefined
        )
      "
      v-for="filter in getInitialFilters"
      :key="filter.code"
      :filter="filter"
      @select-value="onOptionSelectToggle"
    />
  </div>

  <ClientOnly>
    <Teleport to="#navigationbar">
      <SharedFormButton type="button" size="big" class="w-full" @click="filterModalController.open">
        <template v-if="countFilters > 0">{{ countFilters }} Filter angewendet</template>
        <template v-else>Produkte filtern</template>
      </SharedFormButton>
    </Teleport>

    <SharedModal :controller="filterModalController" size="large">
      <template v-slot:title>Filter</template>
      <template v-slot:mobileToolbar>
        <SharedFormButton type="button" size="big" :loading="isLoading" class="w-full">Ergebnisse anzeigen <span v-if="!isLoading">({{ getTotal }})</span></SharedFormButton>
      </template>
      <div>
        <div v-if="showResetFiltersButton" class="pt-4 pb-12 border-b-px border-maas-border-grey-light">
          <SharedFormButton type="button" layout="secondaryDark" @click="invokeCleanFilters" class="w-full">{{ $t("listing.resetFilters") }}</SharedFormButton>
        </div>
        <component
          :is="
            mapComponent(
              filter.code,
              (filter.name == 'Größe' ? 'size' : '') ||
                (filter.name == 'Material' ? 'material' : '') ||
                filter.displayType ||
                undefined
            )
          "
          v-for="filter in getInitialFilters"
          :key="filter.code"
          :filter="filter"
          @select-value="onOptionSelectToggle"
        />
      </div>
    </SharedModal>
  </ClientOnly>
</template>
